/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
const React = require("react")
const HEAD = require("./src/components/head").default

exports.wrapPageElement = ({ element, props }) => {
  return (
    <>
      <HEAD {...props} />
      {element}
    </>
  )
}
