import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

// const Head = ({ title, description, image, pathname, article }) => (
const Head = props => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: { defaultTitle, titleTemplate, defaultDescription, siteUrl, defaultImage },
      },
    }) => {
      const { pageContext, location } = props
      let metas = null
      let params = {
        title: defaultTitle,
        description: defaultDescription,
        image: defaultImage,
        url: `${siteUrl}${location ? location.pathname : ""}`,
      }
      // Home Page
      if (location.pathname === "/") {
        metas = props.data.allPrismicHomepage.edges[0].node.data.body1.filter(item => item.__typename.includes("Metas"))[0]
        params = {
          title: metas.primary.title.text,
          description: metas.primary.description.text,
          image: metas.primary.image_share.url,
          url: `${siteUrl}${location.pathname}`,
        }
      }
      // Other Pages
      if (pageContext !== undefined && pageContext.hasOwnProperty("item") && pageContext.item.node.data.hasOwnProperty("body") && pageContext.item.node.data.body.map(item => (item.__typename.includes("Metas") ? true : false))) {
        metas = pageContext.item.node.data.body.filter(item => item.__typename.includes("Metas"))[0]
        params = {
          title: metas.primary.title.text,
          description: metas.primary.description.text,
          image: metas.primary.image_share.url,
          url: `${siteUrl}${location.pathname}`,
        }
      }

      // console.log(location.pathname, params)

      return (
        <>
          <Helmet title={params.title} titleTemplate={titleTemplate}>
            {/* <!-- Primary Meta Tags --> */}
            <meta name="facebook-domain-verification" content="co4hu3j55qub16y5ermh79f4rbk35z" />
            <meta name="title" content={params.title}></meta>
            <meta name="description" content={params.description} />
            <meta name="image" content={params.image} />
            <link rel="canonical" href={params.url}></link>

            {/* Font Proximanova */}
            <link rel="stylesheet" href="https://use.typekit.net/sfl5byy.css"></link>

            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content={params.url} />
            <meta property="og:title" content={params.title} />
            <meta property="og:description" content={params.description} />
            <meta property="og:image" content={params.image} />

            {/* <!-- Twitter --> */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://www.calorex.com.mx" />
            <meta property="twitter:title" content={params.title} />
            <meta property="twitter:description" content={params.description} />
            <meta property="twitter:image" content={params.image} />

            <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />

            {/* <!-- Favicon --> */}
            <link rel="apple-touch-icon-precomposed" sizes="57x57" href="/metas/apple-touch-icon-57x57.png" />
            <link rel="apple-touch-icon-precomposed" sizes="114x114" href="/metas/apple-touch-icon-114x114.png" />
            <link rel="apple-touch-icon-precomposed" sizes="72x72" href="/metas/apple-touch-icon-72x72.png" />
            <link rel="apple-touch-icon-precomposed" sizes="144x144" href="/metas/apple-touch-icon-144x144.png" />
            <link rel="apple-touch-icon-precomposed" sizes="60x60" href="/metas/apple-touch-icon-60x60.png" />
            <link rel="apple-touch-icon-precomposed" sizes="120x120" href="/metas/apple-touch-icon-120x120.png" />
            <link rel="apple-touch-icon-precomposed" sizes="76x76" href="/metas/apple-touch-icon-76x76.png" />
            <link rel="apple-touch-icon-precomposed" sizes="152x152" href="/metas/apple-touch-icon-152x152.png" />
            <link rel="icon" type="image/png" href="/metas/favicon-196x196.png" sizes="196x196" />
            <link rel="icon" type="image/png" href="/metas/favicon-96x96.png" sizes="96x96" />
            <link rel="icon" type="image/png" href="/metas/favicon-32x32.png" sizes="32x32" />
            <link rel="icon" type="image/png" href="/metas/favicon-16x16.png" sizes="16x16" />
            <link rel="icon" type="image/png" href="/metas/favicon-128.png" sizes="128x128" />
            <meta name="application-name" content="&nbsp;" />
            <meta name="msapplication-TileColor" content="#FFFFFF" />
            <meta name="msapplication-TileImage" content="mstile-144x144.png" />
            <meta name="msapplication-square70x70logo" content="mstile-70x70.png" />
            <meta name="msapplication-square150x150logo" content="mstile-150x150.png" />
            <meta name="msapplication-wide310x150logo" content="mstile-310x150.png" />
            <meta name="msapplication-square310x310logo" content="mstile-310x310.png" />
           
            <link type="text/css" rel="stylesheet" href="https://savethechildrenv5.zw-callitonce.alestra.net.mx/clientweb/chat-client-v4-STC/css/form.css" />
            <script type="text/javascript">
              {
               ` SERVICE_PATTERN_CHAT_CONFIG = {
                  appId: '7b13c05c3b624c7eac27ab16b4fcc090',
                  apiUrl: 'https://savethechildrenv5.zw-callitonce.alestra.net.mx/clientweb/api/v1',
                  tenantUrl: 'savethechildrenv5.zw-callitonce.alestra.net.mx',
                  chatPath: 'https://savethechildrenv5.zw-callitonce.alestra.net.mx/clientweb/chat-client-v4-STC/'
                          
                  /*        
                  // Default customer data, it can be collected on current site's context
                  first_name: '',
                  last_name: '',
                  email: '',
                  phone_number: ''
                  */
                } `
              }
              </script>
              <script src="https://savethechildrenv5.zw-callitonce.alestra.net.mx/clientweb/chat-client-v4-STC/js/init.js"></script>
          </Helmet>
        </>
      )
    }}
  />
)
export default Head

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}

Head.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
}

const query = graphql`
  query Head {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate: titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`
